import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import authService from "./authService";

const InstructorView = ({reg_tag, setChatLog, activeConversation}) => {
  const [students, setStudents] = useState([]);
  const [refreshStudents, setRefreshStudents] = useState(true);
  const [citationCode, setCitationCode] = useState("");
  const [citationMode, setCitationMode] = useState("");
  const [viewedStudent, setviewedStudent] = useState("");
  const [entryType, setEntryType] = useState("-");
  const navigate = useNavigate();

  const handleUnlock = async (username) => {
    const user = JSON.parse(localStorage.getItem("user"));

    if (user && user.access) {
      const requestData = { reg_tag, username };

      try {
        const res = await fetch("api/unlock_user/", {
          method: "POST",
          headers: {
            Authorization: "Bearer " + user.access,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestData),
        });

        if (res.ok) {
          console.log(`${username} unlocked successfully`);
        } else {
          throw new Error(`HTTP error! status: ${res.status}`);
        }
      } catch (error) {
        console.error("Error received:", error);
        if (error.message.includes("401")) {
          console.error("Unauthorized: Please log in.");
          navigate("/login");
        }
      }
    } else {
      navigate("/login");
    }
    setRefreshStudents(prev => !prev);
    setChatLog([]);
  };

  useEffect(() => {
    const userName = JSON.parse(localStorage.getItem("userName"));
    const user = JSON.parse(localStorage.getItem("user"));
    console.log("requesting section data.");

    if (user && user.access) {
      const requestData = { reg_tag, userName };

      fetch("api/get_section/", {
        method: "POST",
        headers: {
          Authorization: "Bearer " + user.access,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestData),
      })
        .then((res) => {
            if (!res.ok) {
                console.log(res.status);
            }
            return res.json();
        })
        .then((data) => {
          console.log(data.students);
          setStudents(data.students);
        })
        .catch((error) => {
          console.error("Error received:", error);
          if (error.message.includes("401")) {
            console.error("Unauthorized: Please log in.");
            navigate("/login");
          }
        });
    } else {
      navigate("/login");
    }
  }, [reg_tag, refreshStudents, navigate]);

  const handleLookupCitation = (e) => {
    e.preventDefault();
    const user = authService.getCurrentUser();
    const requestData = { reg_tag, citationCode };
    console.log(citationCode);

    if (user && user.access) {
      fetch("api/get_citation/", {
        method: "POST",
        headers: {
          Authorization: "Bearer " + user.access,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestData),
      })
        .then((res) => {
          if (!res.ok) {
            setEntryType("Issue retrieving citation.");
            setviewedStudent("");
          }
          return res.json();
        })
        .then((data) => {
            console.log(data)
            setviewedStudent(data.username);
            setCitationMode(data.mode);
            setEntryType("Citations");
            setChatLog(data.conversation);
        })
        .catch((error) => {
          console.error("Error received:", error);
          if (error.message.includes("401")) {
            console.error("Unauthorized: Please log in.");
            navigate("/login");
          }
        });
    } else {
      navigate("/login");
    }
  };

  const handleViewLockouts = (username) => {
    const user = JSON.parse(localStorage.getItem("user"));

    if (user && user.access) {
      const requestData = { reg_tag, username };

      fetch("api/get_lockouts/", {
        method: "POST",
        headers: {
          Authorization: "Bearer " + user.access,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestData),
        })
        .then((res) => {
            if (!res.ok) {
                throw new Error(`HTTP error! status: ${res.status}`);
            }
            return res.json();
        })
        .then((data) => {
            console.log(data)
            setviewedStudent(data.username);
            setEntryType("Potential circumventions");
            setChatLog(data.conversation);
        })
        .catch((error) => {
            console.error("Error received:", error);
            if (error.message.includes("401")) {
                console.error("Unauthorized: Please log in.");
                navigate("/login");
            }
        });
    } else {
    navigate("/login");
    }
    setRefreshStudents(prev => !prev);
  };

  const handleViewRecent = (username) => {
    const user = JSON.parse(localStorage.getItem("user"));

    if (user && user.access) {
      const requestData = { reg_tag, username };

      fetch("api/get_recent_student_activity/", {
        method: "POST",
        headers: {
          Authorization: "Bearer " + user.access,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestData),
        })
        .then((res) => {
            if (!res.ok) {
                throw new Error(`HTTP error! status: ${res.status}`);
            }
            return res.json();
        })
        .then((data) => {
            console.log(data)
            setviewedStudent(data.username);
            setEntryType("Recent Conversation History");
            setChatLog(data.conversation);
        })
        .catch((error) => {
            console.error("Error received:", error);
            if (error.message.includes("401")) {
                console.error("Unauthorized: Please log in.");
                navigate("/login");
            }
        });
    } else {
    navigate("/login");
    }
  };

  const handleSummary = (e) => {
    e.preventDefault();

    const user_message = "Hey Duck, what has my section been discussing?";
    const error_response = "Sorry, I had some trouble with that.";
    setChatLog([{ input: user_message, output: "CS Duck: Hmmm..."}]);

    const user = authService.getCurrentUser();
    const requestData = { reg_tag, activeConversation:activeConversation.label };

    if (user && user.access) {
      fetch("api/get_section_summary/", {
        method: "POST",
        headers: {
          Authorization: "Bearer " + user.access,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestData),
      })
        .then(async (res) => {
          if (!res.ok) {
            throw new Error(`HTTP error! status: ${res.status}`);
          }

          const contentType = res.headers.get('Content-Type');

          if (contentType && contentType.includes('application/json')) {
            // Regular HTTP response
            const jsonResponse = await res.json();
            console.log("Received regular response:", jsonResponse);
            const error_response = jsonResponse.error_response;
            const error_id = jsonResponse.error_id;

            setChatLog((prevChatLog) => {
              const updatedChatLog = [...prevChatLog];
              updatedChatLog[updatedChatLog.length - 1] = {
                input: user_message,
                output: error_response,
                id: error_id
              };
              return updatedChatLog;
            });
          } else if (res.body && typeof res.body.getReader === 'function') {
            // Streaming HTTP response
            const reader = res.body.getReader();
            const decoder = new TextDecoder();
            let chatOutput = "";
            let id = -1;

            const processText = async ({ done, value }) => {
              if (done) {
                console.log("Stream complete");
                return;
              }

              let text = decoder.decode(value);
              if (!chatOutput.length) {
                const last = text.indexOf('|');
                id = parseInt(text.substring(0, last));
                text = text.substring(last+1);
                console.log('attempting to get ID:', id, text);
              }
              chatOutput += text;

              setChatLog((prevChatLog) => {
                const updatedChatLog = [...prevChatLog];
                updatedChatLog[updatedChatLog.length - 1] = {
                  input: user_message,
                  output: chatOutput,
                  id: id,
                };
                return updatedChatLog;
              });

              return reader.read().then(processText);
            };

            return reader.read().then(processText);
          } else {
            throw new Error("Unexpected response type");
          }
        })
        .catch((error) => {
          console.error("Error received:", error);
          if (error.message.includes("401")) {
            console.error("Unauthorized.");
          }
          setChatLog((prevChatLog) => {
            const updatedChatLog = [...prevChatLog];
            updatedChatLog[updatedChatLog.length - 1] = {
              input: user_message,
              output: error_response,
              id: Math.round(Math.random()*-10000)
            };
            return updatedChatLog;
          });
        });
    } else {
      navigate("/login");
    }
  };

  return (
    <div className="InstructorView">

    <h3>Viewing:</h3>
      {viewedStudent ?
        (
        <div
        style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: "small"
        }}
        >
        <p style={{ margin: 0 }}>
          {entryType} from: {viewedStudent} 
          {entryType === 'Citations' ? (
            <>
              <br />
              Mode: {citationMode}
            </>
          ) : ('-')}
        </p>
        </div>
        ) :
        (<p>{entryType}</p>)
      }

    <h3>Lookup Citation</h3>
      <form onSubmit={handleLookupCitation}>
        <label>
          Citation Code:
          <input
            type="text"
            value={citationCode}
            onChange={(e) => setCitationCode(e.target.value)}
            placeholder="Enter citation code"
          />
        </label>
        <button type="submit">Lookup</button>
      </form>

      <h3>Class Status</h3>
      <form onSubmit={handleSummary}>
        <button type="submit">Section Activity Report</button>
      </form>
      <div className="roster">
        {students.length > 0 ? (
            <ul>
            {students.map((student, index) => (
                <li key={index}>
                {student.locked ? (
                    <div clasName="LockoutUI">
                    <p>{student.user} -</p>
                      <div className="LockoutButtons" style={{display:'flex', gap:'3px'}}>
                      Circumvention Suspected:
                      <button
                          onClick={() => handleUnlock(student.user)}
                          title="Clear all student's attempts to circumvent Duck."
                      >Clear</button>
                      <button
                          onClick={() => handleViewLockouts(student.user)}
                          title="View all student's recent and uncleared attempts to circumvent Duck."
                          >Inspect</button>
                      </div>
                    </div>
                ) : (
                  <div className="NormalReview">
                  {student.user} -
                  <button
                      style={{marginLeft:"5px"}}
                      onClick={() => handleViewRecent(student.user)}
                      title="View student's recent conversation with Duck."
                  >View Conversation</button>
                  </div>
                )}
                </li>
            ))}
            </ul>
        ) : (
            <p>No students found for this section.</p>
        )}
      </div>


    </div>
  );
};

export default InstructorView;