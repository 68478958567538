import React, { useState } from "react";
import authService from "./authService";

const BugReportModal = ({ activeConversation, onClose, navigate, reg_tag }) => {
  const [bugMessage, setBugMessage] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submissionStatus, setSubmissionStatus] = useState(null);

  const handleSubmit = () => {
    const user = authService.getCurrentUser();
    if (!user || !user.access) {
      console.error("Unauthorized: Please log in.");
      navigate("/login");
      return;
    }

    const requestData = {
      message: bugMessage,
      activeConversation: activeConversation.label,
      reg_tag: reg_tag
    };

    setIsSubmitting(true);

    fetch("api/bug_report/", {
      method: "POST",
      headers: {
        Authorization: "Bearer " + user.access,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestData),
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error(`HTTP error! status: ${res.status}`);
        }
        return res.json();
      })
      .then(() => {
        setSubmissionStatus("success");
        setBugMessage("");
        setIsSubmitting(false);
        setTimeout(onClose, 1500); // Auto-close after success
      })
      .catch((error) => {
        console.error("Error submitting bug report:", error);
        setSubmissionStatus("error");
        setIsSubmitting(false);
      });
  };

  return (
    <div className="modal-overlay">
      <div className="modal">
        <h2>Report a Bug</h2>
        <textarea
          placeholder="Describe the bug you encountered. Note your recent conversation history will automatically be included. Include below your recent actions (buttons pressed, prompt mode, etc) as relevant."
          value={bugMessage}
          onChange={(e) => setBugMessage(e.target.value)}
          rows="5"
          disabled={isSubmitting}
        />
        <div className="modal-actions">
          <button onClick={handleSubmit} disabled={isSubmitting || !bugMessage}>
            {isSubmitting ? "Submitting..." : "Submit"}
          </button>
          <button onClick={onClose} disabled={isSubmitting}>
            Cancel
          </button>
        </div>
        {submissionStatus === "success" && (
          <p className="success-message">Bug report submitted successfully!</p>
        )}
        {submissionStatus === "error" && (
          <p className="error-message">Failed to submit bug report.</p>
        )}
      </div>
    </div>
  );
};

export default BugReportModal;